const Footer = () => {
  return (
    <div id="Footer">
      <hr />

      <p>
        <span>Posh Plants ltd.</span> &nbsp;
        <span>2022</span>
      </p>
    </div>
  )
}
export default Footer